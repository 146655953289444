<template>
	<main class="page-container" id="sme-we">
		<div class="page-header" style="padding: 55px 0 30px 0">
			<div class="page-component">
				<!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
				<div v-if="isMobile" class="box_select">
					<button type="button" id="select_btn" @click="click">소상공인 인터뷰</button>
					<ul id="select_list">
						<li v-for="(item, idx) in menus[0].children" :key="idx">
							<a :href="item.path">- {{ item.title }}</a>
						</li>
					</ul>
				</div>
				<!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->
				<!-- <div v-if="isMobile" class="section-notice-arrow">
          <button type="button" class="btn-arrow disabled">
            <i class="icon-arrow-prev">이전 페이지</i>
          </button>
          <a href="/playground?ctgrCode=1001001" class="btn-arrow">
            <i class="icon-arrow-next">다음 페이지</i>
          </a>
        </div> -->
				<div class="page-header-title">
					<h2 class="title">소상공인 인터뷰</h2>
					<p class="description">한국중소벤처기업유통원 온라인판로지원사업을 통해 성공한 소상공인의 이야기를 들려드립니다!</p>
				</div>
			</div>
		</div>
		<div class="page-body" data-fill="true">
			<div class="page-component">
				<div class="post-list-wrapper notice-list-wrapper">
					<div class="list-header">
						<template v-if="isMobile">
							<div class="filter-selector">
								<select v-model="sortCd" name="" id="" class="form-select" required="" @change="selectSort">
									<option value="" selected>최신순</option>
									<option value="I">인기순</option>
								</select>
							</div>
						</template>
						<template v-else>
							<div class="header-column">
								<div class="filter-selector">
									<select v-model="sortCd" name="" id="" class="form-select" required="" @change="selectSort">
										<option value="" selected>최신순</option>
										<option value="I">인기순</option>
									</select>
								</div>
							</div>
						</template>
					</div>
					<interview-card :items="items" />

					<div v-if="hasMore" class="page-buttons" data-align="center" @click="morePage">
						<button class="btn btn-lg btn-outline-white">
							<span class="text">더보기</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { ACT_GET_INTERVIEW_LIST } from '../../../store/_act_consts';
import { getItems, lengthCheck, setPaging, tryResCallback } from '../../../assets/js/utils';
import InterviewCard from '../../../components/interview/InterviewCard';
import { mapGetters } from 'vuex';
import menus from '../../../assets/js/menus';

export default {
	name: 'Interview',
	components: {
		InterviewCard,
	},
	data: () => ({
		hasMore: false,
		totalCount: 0,
		pageSize: 12,
		pageNo: 1,
		sortCd: '',
		items: [],
		tryCount: 0,
		menus: menus.filter((x) => x.code === 'information'), //모바일 중메뉴 데이터 (20220714 - hib)
	}),
	computed: {
		...mapGetters('common', ['isMobile']),
	},
	created() {
		this.getInterviewList();
	},
	methods: {
		getInterviewList(isInit) {
			if (isInit) {
				this.items = [];
				this.pageNo = 1;
			}
			this.$store
				.dispatch(`information/${ACT_GET_INTERVIEW_LIST}`, {
					pageNo: this.pageNo,
					pageSize: this.pageSize,
					sortCd: this.sortCd,
				})
				.then((res) => {
					tryResCallback(this.tryCount, res, this.getInterviewList, this.setInterview);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		setInterview(res) {
			if (lengthCheck(res)) {
				this.items = this.items.concat(getItems(res));
			}
			setPaging(this, res);
		},
		morePage() {
			if (this.hasMore) this.pageNo++;
			this.getInterviewList();
		},
		selectSort() {
			this.getInterviewList(true);
		},
		/* 중메뉴이동 (20220714 - hib) */
		click() {
			let element = document.getElementById('select_list');
			element.classList.toggle('show_list');
			let element02 = document.getElementById('select_btn');
			element02.classList.toggle('active');
		},
	},
};
</script>
